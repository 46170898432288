




























import Vue from "vue";
import { mapState } from "vuex";
import { dispatch, ListQuery, Product } from "@/store";

export default Vue.extend({
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState("products", ["list", "query", "count"]),
  },
  methods: {
    getList(query: ListQuery) {
      this.loading = true;
      dispatch
        .productsGetList({
          ...query,
          ids: this.$route.query.ids as string,
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onRefresh() {
      this.getList({
        ...this.query,
        page_number: 1,
      });
    },
    onLoad() {
      this.getList({
        ...this.query,
        page_number: this.query.page_number + 1,
      });
    },
    onProductClick(product: Product) {
      const { user_coupon_id_am_code } = this.$route.query;
      const query = user_coupon_id_am_code ? { user_coupon_id_am_code } : {};
      if (product.product.is_hidden) {
        this.$router.push({
          path: this.$paths.productsHidden + "/" + product.product.hidden_id,
          query,
        });
      } else {
        this.$router.push({
          path: this.$paths.products + "/" + product.product.id,
          query,
        });
      }
    },
  },
  mounted() {
    this.onRefresh();
  },
});
